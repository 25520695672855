import * as React from "react"
import './src/styles/main.scss'

export const onRenderBody = ({ setHeadComponents }) => {
    console.log("script added")
      setHeadComponents([
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-6LS821EKD7"></script>,
        <script key="gtag" type="text/javascript">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          
            gtag('config', 'G-6LS821EKD7');
          `}
        </script>,
      ]);
    
  };